<template>
  <div>
    <div class="sculptor">
            <div class="top-section tw-flex tw-items-center">
                <p>Support</p>
            </div>

            <div class="tw-flex card-sculptors">
                <div class="total-unit">
                    <div class="tw-flex tw-items-center">
                        <icons name="headset" color="#1058bb" class="mr-1" />
                        <span class="darker-text ml-1">On Queue</span>
                        <v-spacer></v-spacer>
                        <div class="tw-flex">
                            <span class="lighter-text">Today</span>
                            <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
                        </div>
                    </div>
                    <p class="total-unit-figure mt-3">5</p>
                    <div class="tw-flex tw-justify-end">
                        <icons name="temp-graph" color="#1058bb" />
                    </div>
                </div>
                <div class="total-unit">
                    <div class="tw-flex tw-items-center">
                        <icons name="headset" color="#B41ECD" class="mr-1" />
                        <span class="darker-text ml-1">Tickets</span>
                        <v-spacer></v-spacer>
                        <div class="tw-flex">
                            <span class="lighter-text">Today</span>
                            <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
                        </div>
                    </div>
                    <p class="total-unit-figure mt-3">10</p>
                    <div class="tw-flex tw-justify-end">
                        <icons name="temp-graph" color="#B41ECD" />
                    </div>
                </div>
                <div class="total-unit">
                    <div class="tw-flex tw-items-center">
                        <icons name="headset" color="#814CC6" class="mr-1" />
                        <span class="darker-text ml-1">Chats</span>
                        <v-spacer></v-spacer>
                        <div class="tw-flex">
                            <span class="lighter-text">Today</span>
                            <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
                        </div>
                    </div>
                    <p class="total-unit-figure mt-3">15</p>
                    <div class="tw-flex tw-justify-end">
                        <icons name="temp-graph" color="#814CC6" />
                    </div>
                </div>
                <div class="total-unit">
                    <div class="tw-flex tw-items-center">
                        <icons name="headset" color="#75B2C7" class="mr-1" />
                        <span class="darker-text ml-1">Calls</span>
                        <v-spacer></v-spacer>
                        <div class="tw-flex">
                            <span class="lighter-text">Today</span>
                            <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
                        </div>
                    </div>
                    <p class="total-unit-figure mt-3">50</p>
                    <div class="tw-flex tw-justify-end">
                        <icons name="temp-graph" color="#75B2C7" />
                    </div>
                </div>
                <div class="categories-box">
                    <div class="tw-flex tw-items-center">
                        <span class="lighter-text">Complaints</span>
                        <v-spacer></v-spacer>
                        <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
                    </div>
                    <div class="tw-flex inner-sculptor">
                        <v-col cols="6" sm="6" style="padding:5px">
                            <p class="total-unit-figure mt-1 small-darker-text">100</p>
                            <span class="status-tags" style="color:#0F674E">Active</span>
                        </v-col>
                        <v-col cols="6" sm="6" style="padding:5px">
                            <p class="total-unit-figure mt-1 small-darker-text">10</p>
                            <span class="status-tags" style="color:#71011C ">Resolved</span>
                        </v-col>
                    </div>
                </div>
            </div>
    </div>
  </div>
</template>

<script>
import Icons from '../../components/reuseables/Icons.vue'
export default {
  components: { Icons },
    name:"SupportDashboard"
}
</script>

<style lang="scss" scoped>
.sculptor {
        width: 100%;
    }

    .top-section {
        @media screen and (max-width:576px) {
            flex-direction: column;
            align-items: start;
        }
    }

    .top-section p {
        color: #263238;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }

    .laptop-view {
        @media (max-width:768px) {
            display: none;
        }
    }

    .mobile-view {
        display: none;

        @media (max-width:768px) {
            display: flex;
        }
    }

    .add-unit-btn {
        display: flex;
        color: #004AAD;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        letter-spacing: 0px;
        padding: 12px;
        border-radius: 10px;
    }

    .add-unit-btn:hover {
        background-color: #c1bfbf4c;
    }

    .total-unit {
        width: 280px;
        height: 140px;
        border-radius: 10px;
        padding: 22px;
        background: #FEFFFF;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

        @media (max-width: 620px) {
            width: 100%;
        }
    }

    .categories-box {
        width: 130px;
        height: 150px;
        padding: 10px 15px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #FEFFFF;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

        @media (max-width: 620px) {
            height: fit-content;
            width: 240px;
        }
    }

    .search-div {
        width: 300px;

        .v-input {
            width: 100%;
        }

        @media screen and (max-width:1264px) {
            width: 100%;
            margin-top: 15px;
        }
    }

    .search-id-div {
        width: 180px;
        margin-left: 15px;

        .v-input {
            width: 100%;
        }

        @media screen and (max-width:576px) {
            width: 100%;
            margin-top: 10px;
            margin-left: 0;
        }
    }

    .inner-sculptor {
        flex-direction: column;

        @media (max-width: 620px) {
            flex-direction: row;
        }
    }

    .darker-text,
    .lighter-text {
        color: #263238;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 400;
        line-height: normal;
    }

    ::v-deep .tf-styles input::placeholder {
        color: #959595;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        ;
    }

    .lighter-text {
        color: #838383;
    }

    .total-unit-figure {
        color: #4F4F4F;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .small-darker-text {
        font-size: 16px;
    }

    .status-tags {
        font-family: Inter;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .v-input {
        width: 200px;
    }

    .company-dashboard {
        display: flex;
        background: #FFFFFF;
        width: 100%;
        min-height: 70vh;
        max-height: available;
        border-radius: 10px;
        flex-direction: column;
    }

    .card-sculptors {
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 20px;

        @media (max-width: 620px) {
            flex-direction: column;
        }
    }

    .v-chip.v-size--default {
        width: 85px;
        display: flex;
        justify-content: center;
    }

    .header {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #939393;
        letter-spacing: 0px;
        text-transform: capitalize;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .circle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: #7CB0F6;
        /* Set your desired background color here */
        border-radius: 50%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: white;

    }

    .table {
        width: 100%;
    }

    .v-data-table__wrapper>table {
        margin-top: 55px !important;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
        padding-left: 16px;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
    .status {
        color: #939393;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
    .td-style {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 15px;
        letter-spacing: 0;
        text-align: left;
        text-transform: capitalize;
    }

    .company-logo {
        height: 100px;
        width: 100px;
        border-radius: 10px;
        object-fit: contain;
    }

    .no-image {
        display: flex;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        text-align: center;
    }
</style>
